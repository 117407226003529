*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.table th{
  padding: 1rem 0;
}
span{
  display: block;
  padding: .75rem 0;
  border-bottom: 1px solid #dee2e6;
}
span:last-child{
  border-bottom: none;
}
span p{
  width: max-content !important;
}
.table tr td{
  padding: 0;
  text-transform: capitalize;
}

@media print {
  .hide-print {
    display: none;
  }
  .container{
    padding: 0 !important;
  }
}
